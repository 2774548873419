var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout } from '~/core/decorator';
import WorkListImportList from "~/components/case-manage/work-list-import/work-list-import-list.vue";
import WorkOrderImportDetails from "~/components/case-manage/work-list-import/work-order-import-details.vue";
var WorkListImport = /** @class */ (function (_super) {
    __extends(WorkListImport, _super);
    function WorkListImport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentComponent = "WorkListImportList";
        _this.batchNumber = "";
        return _this;
    }
    /**
     * 点击案件详情
     */
    WorkListImport.prototype.onClickCaseDetail = function (rowData) {
        this.batchNumber = rowData.batchNumber;
        this.currentComponent = "WorkOrderImportDetails";
    };
    /**
     * 返回案件导入列表
     */
    WorkListImport.prototype.onBackCaseList = function () {
        this.currentComponent = "WorkListImportList";
    };
    WorkListImport = __decorate([
        Layout('workspace'),
        Component({
            components: {
                WorkListImportList: WorkListImportList,
                WorkOrderImportDetails: WorkOrderImportDetails
            }
        })
    ], WorkListImport);
    return WorkListImport;
}(Vue));
export default WorkListImport;
